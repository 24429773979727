import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const NewsBlog = () => (
    <>
        <Navbar />
        <main className="gd-news--container">
            <h2 className="gd-news--title">Exciting Events at GD Goenka School Hyderabad! 🎉</h2>

            <section className="gd-news--section">
                <div className="gd-news--event">
                    <h3 className="gd-news--event-title">Dandiya Night on 28th September 2024 – A Night of Fun, Fashion, and Festivities!</h3>
                    <p>Get ready to groove to the beats of Dandiya at GD Goenka School, Hyderabad! We are thrilled to announce an exciting Dandiya Night on 28th September 2024. This is not just any ordinary Dandiya event – it’s a DJ Night filled with fun, fashion, food, and dance.</p>
                    <ul>
                        <li>DJ Music: Dance the night away to the electrifying beats of our DJ.</li>
                        <li>Food Stalls: Indulge in mouth-watering treats from various food stalls offering a variety of delicacies.</li>
                        <li>Fashion Exhibition: Explore trendy collections and designer wear at the fashion exhibition.</li>
                        <li>Fashion Show: Parents and kids can showcase their traditional attire and stand a chance to win special prizes.</li>
                    </ul>
                    <p>
                        <strong>Date:</strong> 28th September 2024<br />
                        <strong>Venue:</strong> GD Goenka School Ground, Hyderabad<br />
                        <strong>Time:</strong> 5 PM to 10 PM
                    </p>
                    <p>Dress in your best traditional attire – ghagra for females and sherwani for males is compulsory. Don’t miss this dazzling night of dance, food, and entertainment!</p>
                    <p><strong>Free entry for GD Goenka students, ₹199/- for parents, and ₹499/- for other guests.</strong></p>

                    <p>For more details or registrations</p>
                    <p>☎️ Contact: <a style={{textDecoration:'none', color:'#D6291E'}} href="tel:+91949567143">+91 9949567143</a></p>

                </div>

                <div className="gd-news--event">
                    <h3 className="gd-news--event-title">Adventure and Life Skills Camp: October 2-4, 2024 – Vikarabad Forest</h3>
                    <p>Calling all GD Goenka students for an unforgettable outdoor experience! We are organizing an Adventure and Life Skills Camp from October 2nd to 4th, 2024 in the beautiful Vikarabad Forest.</p>
                    <p>This thrilling 3-day camp will give students the opportunity to:</p>
                    <ul>
                        <li>Develop essential life skills through exciting adventure activities.</li>
                        <li>Learn teamwork, problem-solving, and leadership in a natural setting.</li>
                        <li>Participate in nature trails, trekking, camping, and survival skills.</li>
                    </ul>
                    <p>
                        <strong>Dates:</strong> October 2-4, 2024<br />
                        <strong>Location:</strong> Vikarabad Forest
                    </p>
                    <p>This camp is an excellent opportunity for students to connect with nature, make new friends, and discover their true potential.</p>
                    <p><strong>This event is conducted by <a href='https://learnorama.in' style={{textDecoration:'none', color:'#D6291E'}}>Learn Orama Technologies Pvt Ltd.</a></strong></p>
                    <p>For more details or registrations,
                      <br /> visit: <a style={{textDecoration:'none', color:'#D6291E'}} href="https://learnorama.in/#/winterquestcamp" target="_blank" rel="noopener noreferrer">https://learnorama.in/#/winterquestcamp</a></p>
                    <p>☎️ Contact: <a style={{textDecoration:'none', color:'#D6291E'}} href="tel:+919849856143">+91 9849856143</a></p>
                </div>
            </section>
        </main>
    </>
);

export default NewsBlog;
