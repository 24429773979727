import React from "react";
import Navbar from "../components/Navbar";

const GdGoenkaClubs = () => {
  return (
    <>
      <Navbar />

      <h1 className="box-title2">Gd Goenka Clubs</h1>

      <div className="clubs-container">
        {/* Box 1 */}
        <div className="club-box">
          <h2>Grade 6 - Grade 10</h2>

          <div>
        <h2>Helix Weaver Club</h2>
        <img src="./images/Helix.jpg" alt="Helix Weaver Club" className="club-image" />
        <p>Fashion Designing, Knitting, Stitching</p>
        <p>Grade: 9A</p>
      </div>

      <div>
        <h2>AI Club (Computer Lab)</h2>
        <img src="./images/AI-Club.jpg" alt="AI Club" className="club-image" />
      </div>

      <div>
        <h2>Jurno Junction Club°</h2>
        <img src="./images/Jurno-Junction-Club.png" alt="Jurno-Junction-Club" className="club-image" />
        <p>Journalism & Mass Media</p>
        <p>Grade: 9B</p>
      </div>

      <div>
        <h2>Creation Cart Club</h2>
        <img src="./images/Creation-Cart.jpg" alt="Creation-Cart Club" className="club-image" />
        <p>Art, Craft, Pottery, DIY</p>
        <p> (ART ROOM)</p>
      </div>

      <div>
        <h2>Planet Love Club</h2>
        <img src="./images/Skating-Club.jpg" alt="planet" className="club-image" />
        <p>Social Service, Eco, Gardening, First Aid</p>
        <p>Grade: 8A</p>
      </div>

      <div>
        <h2>Litflix Club</h2>
        <img src="./images/Litflix-Club.jpg" alt="planet" className="club-image" />
        <p>Literature</p>
        <p>(8B) </p>
      </div>

      <div>
        <h2>My League Club</h2>
        <img src="./images/my-league-Club-table-tennis.jpg" alt="my-league-Club-table-tennis" className="club-image" />
        <p>Chess (7A) </p>
        <p>Carrom (7B)</p>
        <p>Table Tennis (Dining Area)</p>
      </div>

      <div>
        <h2>Satya Shala Club</h2>
        <img src="./images/Satya-Shala-Club.jpg" alt="Satya-Shala-Club" className="club-image" />
        <p>Yoga & Meditation</p>
      </div>

      <div>
        <h2>Limelight Club</h2>
        <img src="./images/Limelight-Club.jpg" alt="Limelight-Club" className="club-image" />
        <p>Theatre</p>
        <p>Yoga & Meditation (6A)</p>
      </div>

      <div>
        <h2>7 Notes Club</h2>
        <img src="./images/Notes-Club.jpg" alt="Notes-Club" className="club-image" />
        <p>Drums, Casio, Guitar</p>
        <p> (Music Room)</p>
      </div>

      <div>
        <h2>Sci-fi Club</h2>
        <img src="./images/sci-club.jpg" alt="sci-club" className="club-image" />
        <p>Science</p>
        <p> (Science Lab)</p>
      </div>

      <div>
        <h2>Rhythm Club</h2>
        <img src="./images/Rhythm-Club.jpg" alt="Rhythm-Club" className="club-image" />
        <p>Western Dance</p>
        <p> (Dance Lab)</p>
      </div>

      <div>
        <h2>Pi-rates Club</h2>
        <img src="./images/Pi-rates-Club.jpg" alt="Pi-rates-Club" className="club-image" />
        <p>Mathematics</p>
        <p> (Maths Lab)</p>
      </div>

      <div>
        <h2>Nritya Club</h2>
        <img src="./images/Nritya-Club.jpg" alt="Nritya-Club" className="club-image" />
        <p>Classical Dance</p>
        <p> (Language Room, 3rd Floor)</p>
      </div>

      <div>
        <h2>Photography Club</h2>
        <img src="./images/Photography-Club.jpg" alt="Photography-Club" className="club-image" />
        <p>Grade: 6B</p>
      </div>

      <div>
        <h2>Karate Club</h2>
        <img src="./images/Karate-Club.jpg" alt="Karate-Club" className="club-image" />
        <p> Art, Craft, Pottery, DIY</p>
        <p> (Dining Area)</p>
      </div>

      <div>
        <h2>Sargam Club</h2>
        <img src="./images/Sargam-Club.jpg" alt="Sargam-Club" className="club-image" />
        <p> Vocal (Music Room)</p>
      </div>
    {/* </div> */}


        </div>

        {/* Box 2 */}
        <div className="club-box">
          <h2>Grade 3 - Grade 5</h2>
          <div>
        <h2>Jurno Junction Club</h2>
        <img src="./images/Jurno-Junction-Club2.png" alt="Jurno-Junction-Club2" className="club-image" />
        <p>Journalism & Mass Media</p>
        <p>Grade: 4A</p>
      </div>

      <div>
        <h2>Creation Cart Club</h2>
        <img src="./images/Creation-Cart.jpg" alt="Creation-Cart Club" className="club-image" />
        <p>Art, Craft, Pottery, DIY</p>
        <p>(ART ROOM)</p>
      </div>

      <div>
        <h2>Planet Love Club</h2>
        <img src="./images/planet-Club.jpg" alt="planet" className="club-image" />
        <p>Social Service, Eco, Gardening, First Aid</p>
        <p>Grade: 4B</p>
      </div>

      <div>
        <h2>Skating Club</h2>
        <img src="./images/Skating-Club.jpg" alt="planet" className="club-image" />
        <p> (SKATING GROUND)</p>
      </div>

      <div>
        <h2>My League Club</h2>
        <img src="./images/my-league-Club.jpg" alt="my-league-Club" className="club-image" />
        <p>Chess (4C), Carrom (4D), Table Tennis (MESS AREA)</p>
        
      </div>

      <div>
        <h2>Satya Shala Club</h2>
        <img src="./images/Satya-Shala-Club.jpg" alt="Satya-Shala-Club" className="club-image" />
        <p>Yoga & Meditation</p>
        <p>(MUSIC ROOM)</p>
      </div>

      <div>
        <h2>Limelight Club</h2>
        <img src="./images/Limelight-Club.jpg" alt="Limelight-Club" className="club-image" />
        <p>Theatre</p>
        <p>Grade: (5A)</p>
        
      </div>

      <div>
        <h2>7 Notes Club</h2>
        <img src="./images/Notes-Club.jpg" alt="Notes-Club" className="club-image" />
        <p>Casio</p>
        <p>
        (MUSIC ROOM)</p>
      </div>

      <div>
        <h2>Sci-fi Club</h2>
        <img src="./images/sci-club.jpg" alt="sci-club" className="club-image" />
        
        <p>Science</p>
        <p> (SCIENCE LAB)</p>
      </div>

      <div>
        <h2>Rhythm Club</h2>
        <img src="./images/Rhythm-Club.jpg" alt="Rhythm-Club" className="club-image" />
        <p>Western Dance</p>
        <p> (DANCE ROOM)</p>
      </div>

      <div>
        <h2>Pi-rates Club</h2>
        <img src="./images/Pi-rates-Club.jpg" alt="Pi-rates-Club" className="club-image" />
        <p>Mathematics</p>
        <p> (MATH LAB)</p>
      </div>

      <div>
        <h2>Nritya Club</h2>
        <img src="./images/Nritya-Club.jpg" alt="Nritya-Club" className="club-image" />
        <p>Classical Dance</p>
        <p>(LANGUAGE ROOM, 2ND FLOOR)</p>
      </div>

      <div>
        <h2>Sargam Club</h2>
        <img src="./images/Sargam-Club.jpg" alt="Sargam-Club" className="club-image" />
        <p> Vocal (Music Room)</p>
      </div>

      <div>
        <h2>Karate Club</h2>
        <img src="./images/Karate-Club.jpg" alt="Karate-Club" className="club-image" />
        <p>Art, Craft, Pottery, DIY</p>
        <p>Location: DOUBLE HEIGHTS</p>
        
      </div>
        </div>

        {/* Box 3 */}
        <div className="club-box">
          <h2>Grade 1 - Grade 2</h2>
          <div>
        <h2>Sci-Fi Club</h2>
        <img src="./images/sci-club.jpg" alt="sci-club" className="club-image" />
        <p>Science</p>
        
        <p>Location: SCIENCE LAB</p>
      </div>

      <div>
        <h2>Pi-Rates Club</h2>
        <img src="./images/Pi-rates-Club.jpg" alt="Pi-rates-Club" className="club-image" />
        <p>Mathematics</p>
        
        <p>Location: MATH LAB</p>
      </div>

      <div>
        <h2>Creation Cart Club</h2>
        <img src="./images/Creation-Cart.jpg" alt="Creation-Cart Club" className="club-image" />
        <p>Art, Craft, Pottery, DIY</p>
        
        <p>Location: ART ROOM</p>
      </div>

      <div>
        <h2>Karate Club</h2>
        <img src="./images/Karate-Club.jpg" alt="Karate-Club" className="club-image" />
        <p>Art, Craft, Pottery, DIY</p>
        <p>Location: DOUBLE HEIGHTS</p>
      </div>

      <div>
        <h2>Satya Shala Club</h2>
        <img src="./images/Satya-Shala-Club.jpg" alt="Satya-Shala-Club" className="club-image" />
        <p>Yoga & Meditation</p>
        
        <p>Location: CORRIDOR FIRST FLOOR</p>
      </div>

      <div>
        <h2>Rhythm Club</h2>
        <img src="./images/Rhythm-Club.jpg" alt="Rhythm-Club" className="club-image" />
        <p>Western Dance</p>
        
        <p>Location: DANCE ROOM</p>
      </div>

      <div>
        <h2>Nritya Club</h2>
        <img src="./images/Nritya-Club.jpg" alt="Nritya-Club" className="club-image" />
        <p>Classical Dance</p>
        
        <p>Location: LANGUAGE ROOM</p>
      </div>

      <div>
        <h2>Satyam Club</h2>
        <img src="./images/Sargam-Club.jpg" alt="Sargam-Club" className="club-image" />
        <p>Vocal</p>
        
        <p>Location: MUSIC ROOM</p>
      </div>

      <div>
        <h2>My League Club</h2>
        <img src="./images/my-league-Club.jpg" alt="my-league-Club" className="club-image" />
        <p>Lego / Board Games / Table Tennis</p>
       
        <p>Location: INDOOR SPORTS ROOM</p>
      </div>
        </div>
      </div>
    </>
  );
};

export default GdGoenkaClubs;
