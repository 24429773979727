import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const SportsManagement = () => (
    <>
    
    <Navbar />

    <div className="banner">
        <img src="./images/Sports Management.jpg" />
      </div>
    
    <section className="section-mainbox">
    <h2>Sports Management</h2>
    <p>At <em>GD Goenka</em>, we believe that physical education and sports are crucial for the overall development of our students. Our <strong>Sports Management Program</strong> offers a wide variety of sports, including cricket, football, basketball, athletics, tennis, swimming, and more. We are equipped with world-class facilities, such as international standard playgrounds, well-maintained courts, and specialized sports equipment to cater to all interests.</p>
    <ul>
        <li><strong>Expert Coaching:</strong> Our team of experienced coaches is dedicated to training students in various sports disciplines, focusing on skill development, fitness, and teamwork.</li>
        <li><strong>Regular Events and Competitions:</strong> We organize regular sports events and inter-school competitions to foster a spirit of healthy competition and sportsmanship.</li>
        <li><strong>Personalized Training Programs:</strong> Tailored training sessions are available for students who wish to excel in specific sports, with an emphasis on discipline, perseverance, and strategic thinking.</li>
    </ul>
    <p>Through our comprehensive sports management program, we aim to instill the values of teamwork, leadership, resilience, and fair play, which are essential for success both on and off the field.</p>
</section>
  
  </>
);

export default SportsManagement;
