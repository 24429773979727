import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const AdvancedScienceLabs = () => (
    <>
    
    <Navbar />
    
    <div className="banner">
        <img src="./images/Advanced science labs.jpg" />
      </div>
    
    <section className="section-mainbox">
    <h2>Advanced Science Labs</h2>
    <p>At <em>GD Goenka</em>, we believe in the power of experiential learning to ignite curiosity and foster a deep understanding of scientific concepts. Our <strong>Advanced Science Labs</strong> are designed to provide students with a hands-on, inquiry-based approach to learning.</p>
    <ul>
        <li><strong>Cutting-Edge Facilities:</strong> Our Physics, Chemistry, and Biology labs are equipped with state-of-the-art apparatus, modern instruments, and digital tools to facilitate a wide range of experiments and research activities. Each lab is designed to support innovative learning and experimentation in a safe environment.</li>
        <li><strong>Hands-On Experiments:</strong> Students are encouraged to actively engage in scientific exploration, from conducting basic experiments to designing complex research projects. Our labs provide a platform for practical application of theoretical knowledge, enhancing their understanding of core scientific principles.</li>
        <li><strong>Interactive Learning:</strong> Advanced simulation tools and digital resources are integrated into the labs to provide a more interactive and immersive learning experience. Students can visualize complex scientific phenomena and conduct virtual experiments.</li>
        <li><strong>Focus on Safety:</strong> Safety is a top priority in our science labs. All experiments are conducted under strict supervision, following established safety protocols. Students receive training in lab safety practices to ensure a secure environment for all.</li>
        <li><strong>STEM Integration:</strong> Our science labs are an integral part of our STEAM (Science, Technology, Engineering, Arts, and Mathematics) education framework, encouraging interdisciplinary learning. Students collaborate on projects that combine elements of science, technology, and engineering, preparing them for future careers in these fields.</li>
    </ul>
    <p>By providing access to advanced scientific resources and fostering a culture of inquiry and experimentation, GD Goenka, Gandipet, empowers students to explore the wonders of science and develop a passion for discovery.</p>
</section>
  
  </>
);

export default AdvancedScienceLabs;
