import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const HealthcareBlog = () => (
    <>
    
    <Navbar />
    
    <div className="section-mainbox">
      <h1>Healthcare Blog and Services</h1>
      <p>
        The health and well-being of our students are of paramount importance at <strong>GD Goenka</strong>. We have partnered with <strong>Kaminene Hospital</strong> to provide comprehensive healthcare services on campus. Our dedicated <em>Healthcare Blog</em> offers valuable insights into student health, wellness, and safety.
      </p>
      <ul>
        <li><strong>On-Campus Medical Room:</strong> Our school is equipped with a fully functional medical room staffed by a qualified nurse to provide immediate care for minor injuries, illnesses, and medical emergencies. Regular health check-ups and vaccination drives are conducted to monitor and maintain the well-being of our students.</li>
        <li><strong>Health and Wellness Initiatives:</strong> We promote a healthy lifestyle through regular health awareness programs, workshops, and seminars. Topics include nutrition, mental health, hygiene, and fitness, ensuring students are educated about maintaining their well-being.</li>
        <li><strong>Counseling Services:</strong> We offer counseling support to help students manage stress, anxiety, and emotional challenges. Our counselors provide guidance and support to ensure that every child has access to the help they need.</li>
      </ul>
      <p>
        Our <em>Healthcare Blog</em> keeps parents and students informed about the latest updates on health practices, wellness tips, and information on the medical services available at the school. It serves as a valuable resource for staying connected with our health initiatives and fostering a healthy school community.
      </p>
    </div>
  
  </>
);

export default HealthcareBlog;
