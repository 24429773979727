import React, { useEffect } from 'react';
import Navbar from './Navbar';
import '../style.css';
import Animations from './Animations';
import Menu from './Menu';
import Slider from './slider/Slider';
import FactSection from './FactSection';
import HolisticDevelopment from '../Pages/HolisticDevelopment';
import ImageScroll from './ImageScroll';

const Home = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Navbar />
      {/* <Menu /> */}
      <Slider />
      <FactSection />

      {/* YouTube Video Section */}

      <div>
      <h1 className='heading-h1 block animatable bounceIn'>
        Welcome to GD Goenka Hyderabad
      </h1>
      </div>
      <div className="img-container block animatable bounceInLeft">

        <img src='./images/school-building-full.jpg' />
      </div>

      
      <h1 className='heading-h1 block animatable bounceIn'>
      Your Child's Path to Greatness
      </h1>

      <div className="gdg-container">
      <div className="gdg-box gdg-box1 block animatable bounceInRight">
      <h1 className='heading-h1 block animatable bounceIn'>
      Inspiring Excellence
      </h1>
      <p>
          GD Goenka School is Hyderabad's first school to offer state-of-the-art AI labs, high-end robotics and science labs, and international-standard playgrounds. Our air-conditioned classrooms and globally recruited teachers ensure a holistic education system integrated with CBSE and international standards, nurturing the next generation of thinkers and innovators. By combining these online, digital, AI, social media, and offline marketing strategies, the school can maximize visibility, attract prospective students, and establish itself as a premier educational institution in Hyderabad.
        </p>
      </div>
      <div className="gdg-box gdg-box2 block animatable bounceInLeft">
        <img src="./images/home-pg-img11.jpg" alt="GD Goenka School" />
      </div>
    </div>
<HolisticDevelopment />

<ImageScroll />

    </>
  );
}

export default Home;
