import React from 'react';
import './CommonStyles.css';
import Navbar from '../components/Navbar';

const EducationalConcepts = () => (
    <>
    
    <Navbar />

    <div className="banner">
        <img src="./images/Advanced computer labs.jpg" />
      </div>
    
    <div className="section-mainbox">
    <h2 className="gd-title">Innovative Educational Concepts at GD Goenka</h2>
    <ul className="gd-list">
      <div className="gd-list-item">
        <h3 className="gd-subtitle">1. STEAM Education:</h3>
        <ul>
          <li>- A unique approach combining Science, Technology, Engineering, Arts, and Mathematics.</li>
          <li>- Hands-on projects and real-world problem-solving to enhance learning experiences.</li>
        </ul>
      </div>
      <div className="gd-list-item">
        <h3 className="gd-subtitle">2. Digital Literacy:</h3>
        <ul>
          <li>- Comprehensive integration of digital tools and platforms for an enhanced learning environment.</li>
          <li>- Training in cutting-edge technologies like coding, AI, robotics, and digital media.</li>
        </ul>
      </div>
      <div className="gd-list-item">
        <h3 className="gd-subtitle">3. Inclusive Education:</h3>
        <ul>
          <li>- A welcoming and inclusive environment for students of all backgrounds and abilities.</li>
          <li>- Specialized programs and support for diverse learning needs.</li>
        </ul>
      </div>
      <div className="gd-list-item">
        <h3 className="gd-subtitle">4. Global Perspective:</h3>
        <ul>
          <li>- Exchange programs, international partnerships, and global curriculum integration.</li>
          <li>- Encouraging a multicultural outlook and awareness of global issues.</li>
        </ul>
      </div>
      <div className="gd-list-item">
        <h3 className="gd-subtitle">5. Environmental Awareness:</h3>
        <ul>
          <li>- Education focused on sustainability, conservation, and eco-friendly practices.</li>
          <li>- Active participation in projects and initiatives aimed at environmental responsibility.</li>
        </ul>
      </div>
    </ul>
  </div>
  </>


);

export default EducationalConcepts;
